import jwt from 'jsonwebtoken'

//const queryLocation = 'tQuery_'+process.env.VUE_APP_NAME.replace(/ /g, "-") //.replace(/\s+/, '-') // 
//const logLocation = 'logs_'+process.env.VUE_APP_NAME.replace(/ /g, "-") // .replace(/\s+/, '-') // 
//const TestLocation = 'test_'+process.env.VUE_APP_NAME.replace(/ /g, "-") // .replace(/\s+/, '-') // 
const queryLocation = 'tQuery_Institute-Management-Platform' //.replace(/\s+/, '-') // 
const logLocation = 'logs_Institute-Management-Platform' // .replace(/\s+/, '-') // 
const TestLocation = 'test_Institute-Management-Platform' // .replace(/\s+/, '-') // 
const presenceLocation = 'presence' // +process.env.VUE_APP_NAME.replace(/ /g, "-") // .replace(/\s+/, '-') // 

var user = {
  id: null,
  name: null,
  email: null,
  meritpoints: 0,
  avatar: null,
  exp: -1,
  config: {}
}

var jwt_token = localStorage&&localStorage.getItem('jwt_token')?localStorage.getItem('jwt_token'):null
if(jwt_token) user = jwt.decode(jwt_token)

var auth = localStorage?localStorage.getItem('tm_auth'):false

const now = new Date().getTime() / 1000 

if(user.exp>-1 && +user.exp<now) {
  auth = false
  user = {
    id: null,
    name: null,
    email: null,
    meritpoints: 0,
    avatar: null,
    exp: -1
  }
}

var config = {}
if(localStorage&&localStorage.getItem('config_token')) config = jwt.decode(localStorage.getItem('config_token'))

user.config = config

export  {
    TestLocation,
    queryLocation,
    logLocation,
    presenceLocation,
    auth,
    user,
    jwt_token,
}
