let menus  = {
    others:  
    [
      { title: 'Desk',                  icon: 'web',                 active: false ,  to: '/desk', color: 'orange darken-3', colorHex: '#ef6c00', colorDark: '#b35508'},
      // { title: 'Course Planner',     icon: 'directions',          active: false  , to: '/course-planner'},
      { title: 'Timetable',             icon: 'today',               active: true  ,  to: '/timetable', color: 'success', colorHex: '#2E7D32', colorDark: '#1B5E20'},
      { title: 'Study Material',        icon: 'auto_stories',       to: '/study?type=material',     color: '#00695C', colorHex: '#00695C', colorDark: '#004D40'},
      { title: 'Homework',              icon: 'home_work',          to: '/homework', color: '#EA80FC', colorHex: '#EA80FC', colorDark: '#077a88'},
      { title: 'Assignment',            icon: 'event_note',         to: '/assignment', color: 'cyan', colorHex: '#077a88', colorDark: '#077a88'},
      { title: 'Video Lecture',         icon: 'slideshow',            active: true  ,  to: '/video-lecture', color: 'blue-grey darken-2', colorDark: '#274757', colorHex: '#455a64'},
      { title: 'Assessment',            icon: 'quiz',        active: true  ,  to: '/test', color: 'success', colorHex: '#2E7D32', colorDark: '#1B5E20'},
      { title: 'Previous Year',         icon: 'quiz',        active: true  ,  to: '/previous-year?tags=previous-year', color: 'success', colorHex: '#2E7D32', colorDark: '#1B5E20'},
      { title: 'Result',                icon: 'playlist_add_check',  active: false,   to: '/test-results' ,  color: 'orange darken-3', colorHex: '#ef6c00', colorDark: '#b35508'},
      { title: 'Marksheet',             icon: 'rule',                active: false,   to: '/marksheet',  color: '#00695C', colorHex: '#00695C', colorDark: '#004D40' },
      //{ title: 'Answer Sheet',        icon: 'rule', to: '/answer-sheet', color: '#EA80FC', colorHex: '#EA80FC', colorDark: '#077a88'},
      { title: 'Attendance',            icon: 'how_to_reg', to: '/attendance', color: 'success',  colorHex: '#2E7D32', colorDark: '#1B5E20'},
      { title: 'My Fee',                icon: 'receipt_long', to: '/fee-list', color: 'orange darken-3', colorHex: '#ef6c00', colorDark: '#b35508'},
      { title: 'Certificate',           icon: 'badge', to: '/certificates', color: 'blue-grey darken-2', colorDark: '#274757', colorHex: '#455a64'},
      { title: 'Troubleshoot',          icon: 'build',               active: true  ,  to: '/troubleshoot', color: 'cyan', colorHex: '#00bcd4', colorDark: '#077a88'},
      { title: 'Profile',               icon: 'account_circle',               active: true  ,  to: '/profile', color: 'blue-grey darken-2', colorDark: '#274757', colorHex: '#455a64'},
      { title: 'Query',                 icon: 'forum', to: '/queries', color: 'orange darken-3', colorHex: '#ef6c00', colorDark: '#b35508'},
      // { title: 'Test Analytics',     icon: 'show_chart',         active: false,   to: '/test-analytics'  },
      // { title: 'Night Mode',         icon: 'brightness_3',    active: false, right_icon: 'lock'  }
    ]
  }
  

  function filteredMenu(config = null, from){
    if(!config) return menus.others
    return menus.others.filter(menu => {
      return config[menu.title.toUpperCase().split(' ').join('_')]
    })
  }

  export {filteredMenu}

  export default menus