let CURRENT = 0
let SIGNEDURL = null
const {instance} = require('./../../../_axios.js');
import Webcam, {GetImageBlob} from './Webcam'
import PINGFIREBASE from './PINGFIREBASE'
import ProctorHelper from './VideoProctor'

var vaniFrame = document.querySelector('#vaniframe')

let hasWebcamAccess = false
let activeStream = null

export default function tick(context) {
    if(!context.testConfig.IMAGE_PROCTOR_URL)  return 
    
    if(!hasWebcamAccess) requestWebcam()

    CURRENT += 1
    if(context.testConfig.PROCTOR_TYPE==ProctorHelper.PROCTOR_TYPES.VANIS){
        if(!vaniFrame || !vaniFrame.contentWindow) vaniFrame = document.querySelector('#vaniframe')
        vaniFrame.contentWindow.postMessage({ "type": "ImageCaptureRequest" }, "*")
    } 
    if(CURRENT >= context.testConfig.IMAGE_PROCTOR_INTERVAL) {
        UPLOADSNAP(context)
        CURRENT = 0
    }
}

async function requestWebcam() {
    let deviceId = localStorage.getItem('CAMERA_DEVICE')
    try {
        const activeStream = await navigator.mediaDevices.getUserMedia({
            video: { deviceId: { exact: deviceId } }
        });
        hasWebcamAccess = true
        console.log("Camera access granted for device:", deviceId);
        
        if (activeStream) {
            const videoElement = document.getElementById("localVideo");
            videoElement.srcObject = activeStream;
            videoElement.autoplay = true;
            //document.body.appendChild(videoElement);
        }
    } catch (error) {
        console.error("Error accessing the camera:", error);
    }
}


// Function to stop the camera stream
function stopCamera() {
    if (activeStream) {
        activeStream.getTracks().forEach(track => track.stop()); // Stop all tracks
        activeStream = null;
        console.log("Camera stream stopped.");
    }
    
    //if (videoElement) {
    //    videoElement.remove(); // Remove the video element
    //    videoElement = null;
    //    console.log("Video element removed.");
    //}
}

const UPLOADSNAP = async function(context){
    try {
        let blob = null
        if(context.testConfig.PROCTOR_TYPE==ProctorHelper.PROCTOR_TYPES.VANIS){
            blob = await GetImageBlob()
        } else {
            blob = await Webcam()
        }
        const url = await UPLOADER(context, blob)
        PostUpload(context)
    } catch (error) {
        console.log(error.message)
    }
}

const PostUpload = function(context){
    console.log('PINGING -' +SIGNEDURL)
    if(!SIGNEDURL) return 
    context.lastImageProctor = SIGNEDURL.split('.png')[0]+'.png'
    PINGFIREBASE(context)
}

const UPLOADER = async function(context, blob, dest = 'stagings'){
    const resp = await instance({ 
        url: `/get-upload-url/${context.userData.mock_id}/${context.$store.state.assessment.activeQuestion.qn}/${context.testConfig.BUCKET}`, 
        //baseURL: context.testConfig.IMAGE_PROCTOR_URL,
        method: 'POST',
        data: { dest }
    })
    SIGNEDURL = resp.data.signedURL

    var options = {
        headers: {
            'Content-Type': 'image/png',
            'x-amz-acl': 'public-read'
        }
    }
    await instance.put(SIGNEDURL, blob, options)
    return SIGNEDURL.split('.png')[0]+'.png'
}

// const VANIUPLOADER = async function(context, imagebitmap){
//     await UPLOADER(context, await ImageBitmapToBlob(imagebitmap))
//     PostUpload(context)
// }


export {UPLOADER, stopCamera}