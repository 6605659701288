<template>
<div>
  <test-toolbar 
    :error="error" 
    :test-name="testData?testData.name:''" 
    :next-disabled="nextDisabled"
    @showqnav="qNavDialog=true"
    >
  </test-toolbar>
    <!-- 
    @showupload="showUploadDialog()"
      :time-left-part-html="testData && testData.format.partwise_timing?timeLeftPartHTML:''" -->
  
  <div v-if="!error">

    <v-dialog v-model="showPreview" fullscreen  style="z-index:202" scrollable>
      <test-preview v-if="showPreview" @close="showPreview=false" ></test-preview>
    </v-dialog>
    <attachment-dialog :testData="testData" :showAttachments="showAttachments" @hide="showAttachments=false"></attachment-dialog>

    <v-layout :class="$vuetify.breakpoint.mdAndUp?'row':'column'" class="mathcontent min80vh">
      <v-flex xs12 md8 :class="{'large-text':largeText}">
        <div v-if="activeQuestion" style="margin-bottom: 100px">
          <question-header :parts="testData.format.parts"></question-header>
        
          <section-instruction :test-data="testData"></section-instruction>
          <question-item 
            ref="activeQuestionItem"
            :stream="localStream?localStream.stream:null"
            @initStream="!isDesktop?initStream(() => {}):null"
            ></question-item>
        </div>
      </v-flex>

      <!-- DESKTOP QUESTION NAVIGATOR -->
      <v-flex xs12 md4 offset-md1 v-if="$vuetify.breakpoint.mdAndUp && questions.length">
        <desktop-q-nav
          :part="testData && testData.format.partwise_timing?api:-1"
          :large-text="largeText"
          @toggleSize="toggleTextSize()"
          :hide-preview="testConfig.HIDE_TEST_PREVIEW"
          @showPreview="() => showPreview=true"
          @showAttachments="() => showAttachments=true"
          >
        </desktop-q-nav>
        <br><a><img src="./../../assets/logo.png" width="100px"></a><br>
      </v-flex>
    </v-layout>
 
<!-- MOBILE QNAVIGATOR -->
<mobile-q-nav
  ref="mq"
  :part="testData && testData.format.partwise_timing?api:-1"
  :hide-preview="testConfig.HIDE_TEST_PREVIEW"
  @close="() => qNavDialog = false"
  @showAttachments="() => showAttachments=true"
  @showPreview="() => { qNavDialog = false; showPreview=true } ">
  <!-- 
  :q-nav-dialog="qNavDialog"
  :select-question="selectQuestion"
    :time-left-html="timeLeftHTML" -->
</mobile-q-nav>

<chat 
  v-if="!testConfig.DISABLE_CHAT && testData" 
  :test-mockid="testData._id" 
  :test-name="testData.name"
  @extendTest="extendTest"
  @startProctor="startProctor( initStream, true)"
  @initStream="initStream(() => {})">
</chat>
<!-- && showChatPanel -->
<bottom-nav 
  :fab="fab"
  :syncing="syncing"
  :overlay-loading="overlayLoading"
  :save-and-next="saveAndNext"
  :prev-question="previousQuestion"
></bottom-nav>
<!-- 
  :disable-chat="testConfig.DISABLE_CHAT"
  :prev-disabled="prevDisabled"
  :next-disabled="nextDisabled"
  @toggleChat="() => showChatPanel=!showChatPanel"
  @showChat="() => showChatPanel=true" -->

<!-- LOADING PROGRESS -->
<overlay-loading :loading="overlayLoading" :message="overlayMessage"></overlay-loading>

<!-- SUBMIT DIALOG -->
<submit-dialog  @submitTest="submitTest()"
  >
   <!--
    :submitdialog="submitdialog" 
      @closeSubmitDialog="submitdialog=false" -->
</submit-dialog>

<!-- UPLOAD DIALOG -->
  <upload-dialog 
    v-if="userData"
    @pdfUploaded="pdfUploaded"
    @imageUploaded="imageUploaded"
    :pdf-files="userData.pu || []" 
    :test-images="userData.iu || []" 
    :pdf-upload="testConfig.PDF_UPLOAD"
    @closeDialog="uploadDialog=false"
    :stream="localStream?localStream.stream:null"
    :show="uploadDialog" :test-id="userData._id"
    @showSubmitDialog="showSubmitDialog()">
  </upload-dialog>

<!-- WARNING DIALOG -->
  <warning-dialog 
    :blur-time="String(blurTime)" 
    :show-warning-dialog="showWarningDialog" 
    @hidewarning="showWarning=false;showWarningTimer=false" 
    :show-warning-timer="showWarningTimer && testConfig.AUTO_TERMINATE">
  </warning-dialog>
  <!-- 
    NOT REQUIRED AS ALREADY DONE ISNISE TEST TIMER
    <multiple-faces  
    v-if="testConfig.DETECT_MULTIFACE && testConfig.PROCTOR_TYPE!=PTYPES.VANIS" 
    @multiFaces="notifyMultiFaces" 
    @modelLoading="setModelLoading" 
    :normal='testConfig.DETECT_FACE_NINTERVAL'
    :gap='testConfig.DETECT_FACE_GINTERVAL'
    DETECT_FACE_GNINTERVAL
    :submitted="submitted">
  </multiple-faces> -->
  </div>
  <error-layout v-else></error-layout>

  <detect-speech v-if="testData && testConfig.DETECT_SPEECH" :mock-id="testData._id"></detect-speech>
  
</div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

import Chat from './../../components/ChatOverlay.vue'
import DetectSpeech from './../../components/DetectSpeech.vue'
import OverlayLoading from './OverlayLoading.vue'
import ErrorLayout from './ErrorLayout.vue'
import BottomNav from './BottomNav.vue'
import TestToolbar from './TestToolbar.vue';
import QuestionHeader from './QuestionHeader.vue'
import WarningDialog from './Warning.vue';
import SubmitDialog from './SubmitDialog.vue'
import UploadDialog from './UploadDialog.vue'
import TestPreview from './TestPreview.vue';
import TestQuestionItem from './../../components/TestQuestionItem.vue';

import MobileQNavigator from './MobileQNavigator.vue';
import AttachmentDialog from './AttachmentDialog.vue';
import DesktopQNavigator from './DesktopQNav.vue';
// import {hangUp, webexHelper} from './webexapi'
import { Duration } from "luxon";
// Utils
 import PINGFIREBASE from './utils/PINGFIREBASE.js'

import {closeFullscreen} from './../../utils/fullscreen.js'
import FireLog from './utils/Firelog'

import ProctorHelper from './utils/VideoProctor'
import SubmitHelper from './utils/SubmitHelper'
import SyncHelper from './utils/SyncHelper'
import ExtendHelper from './utils/ExtendHelper'
// import NavigateHelper from './utils/NavigateHelper'
import MountDestroyHelper from './utils/MountDestroyHelper'
import {NotifyFaceCount} from './utils/DetectFace'
import PreUploadHelper from './utils/PreUploadHelper'
import LoadingHelper from './utils/LoadingHelper'
import SectionInstruction from './SectionInstruction'

import testConfig from './utils/readEnv'
window.api ;


export default{
  name: 'TestInside',
	data(){
	  return {
      api: 0,
      // qn: this.$route.query.qn?parseInt(this.$route.query.qn) : 1,
      totalQuestions: 0,
      testData: null,
      userData: null,
      loading: false,
      overlayLoading: true,
      overlayMessage: '',
      qNavDialog: false,
      fab: -1,
      syncing: false,
      submitdialog: false,
      uploadDialog: false,
      timeLeft: 999999,
      timeLeftPart: 999999,
      duration: 999999,
      error: null,
      faceCount: 1,
      updateIntervalID: null,
      notifyUploadSheet: false,
      
      blurCount: parseInt(sessionStorage.getItem('blurCount')) || 0,
      isBlur: false,
      isFullscreen: false,

      submitted: false,
      
      blurTime: testConfig.MAX_BLUR_ALLOWED_TIME,
      warningOffset: testConfig.WARNING_OFFSET,

      showWarning: false,
      showWarningTimer: false,
      showChatPanel: false,
      showPreview: false,
      showAttachments: false,
      localStream: null,
      denied: {
        screen: false,
        webcam: false
      },
      askingPermission: false,
      permissionMessage: 'Checking webcam and audio permission status...',

      largeText: localStorage.getItem('large_text')==='true',
      timeStart: null,
      timeEnd: null,
      mediaDevices: [],
      mouseOut: false,
      testConfig,
      lastImageProctor: null,
      PTYPES: ProctorHelper.PROCTOR_TYPES,
      isDesktop: window.innerWidth > 500,
    }
  },
  mounted(){        MountDestroyHelper.onMount(this) },
  beforeDestroy(){  MountDestroyHelper.beforeDestroy(this)},
  methods:{
    ...mapActions(['initJitsiProctor', 'updateQuestion', 'setJitsiOptions', 'increaseTimeSpent', 'saveLog', 'hangupVideoProctor', 'setAnswer', 'nextQuestion', 'prevQuestion']),
    initStream(cb = ()=>{}) {               ProctorHelper.initStream(this, cb)},
    startProctor(cb = ()=>{}, now = false){ ProctorHelper.startProctor(this, cb, now) },
    extendTest(minutes){      ExtendHelper(this, minutes)   },
    saveAndNext(){            SyncHelper.SaveAndNext(this)  },
    previousQuestion(){       SyncHelper.PrevQuestion(this)  },
    markQ() {                 SyncHelper.MarkQ(this)        },
    clearQR() {               SyncHelper.ClearQ(this)       },
    answerDeleted(data){      SyncHelper.DeleteAnswer(this, data)      },
    // prev() {                  NavigateHelper.prev(this) },
    // next() {                  NavigateHelper.next(this) },
    // showSubmitDialog(){       SubmitHelper.ShowSubmitDialog(this) },
    submitTest(reason){       SubmitHelper.Submit(this, reason)   },
    showUploadDialog(){       SubmitHelper.ShowPreSubmitDialog(this)},
    toggleTextSize(){         this.largeText = !this.largeText },
    // answer sheet upload before final test
    imageUploaded(data){      PreUploadHelper.ImageUploaded(this, data) },
    pdfUploaded(data){        PreUploadHelper.PdfUploaded(this, data)   },
    setModelLoading(val){     LoadingHelper(this, val, 'Initiating AI video detector ..') },
    notifyMultiFaces(nof = 1){
      // required for jitsi
      this.faceCount = nof
      NotifyFaceCount(this, nof)
    },
	},
  computed: {
    ...mapGetters([ 'inTest', 'user', 'pauseProctor', 'questions', 'firebaseApp', 'jitsiLoaded', 'qi', 'qsi', 'qpi', 'activeQuestion', 'showSectionInstruction']),
    // question(){
    //   if(this.questions.length<1) return null
    //   return this.questions[this.qn-1]
    // },
    prevDisabled() {
      return this.qn <= 1
    },
    nextDisabled() {
      return this.qn==this.totalQuestions
    },
    testURL() {
      return '/test/in/'+this.$route.params.id+"?ts="+Date.now()
    },
    formatTimeLeft() {
      return Duration.fromObject({hours: 0, minutes: 0, seconds: this.timeLeft}).normalize().values
    },
    formatTimeLeftPart() {
      return Duration.fromObject({hours: 0, minutes: 0, seconds: this.timeLeftPart}).normalize().values
    },
    showBottomNav(){
      return !this.submitdialog && !this.overlayLoading
    },
    showWarningDialog(){
      return this.showWarning && this.warningOffset<=0 // && this.testConfig.DETECT_FULLSCREEN
    },
    // timeLeftHTML(){
    //   return TIMELEFTFORMAT(this.formatTimeLeft)
    // },
    // timeLeftPartHTML(){
    //   return TIMELEFTFORMAT(this.formatTimeLeftPart)
    // },
    activePart(){
      if(this.testData) return this.testData.format.parts[this.api]
      else {}
    }
  },
  components: {
    'chat': Chat,
    'bottom-nav': BottomNav,
    'question-item': TestQuestionItem,
    'submit-dialog': SubmitDialog,
    'upload-dialog': UploadDialog,
    'test-toolbar': TestToolbar,
    'warning-dialog': WarningDialog,
    'test-preview': TestPreview,
    // 'multiple-faces': MultipleFaces,
    'detect-speech': DetectSpeech,
    'question-header': QuestionHeader,
    'mobile-q-nav': MobileQNavigator,
    'desktop-q-nav': DesktopQNavigator,
    'overlay-loading': OverlayLoading,
    'error-layout': ErrorLayout,
    'attachment-dialog': AttachmentDialog,
    'section-instruction': SectionInstruction
  },
  watch: {
    'inTest'(val) {
      if(!val) closeFullscreen()
    },
    'pauseProctor'(val) {
      FireLog(this, val?'IMAGE_CAPTURE':'IMAGE_CAPTURE_RESUME')
    },
    // 'submitDialog'(val){
    //   if(true) {
    //     this.submitOverview = submitCount(this.questions)
    //   }
    // }
  }
}
</script>
<style >
.large-text{
  font-size: 200% !important;
}
.min80vh{
  min-height: 80vh;
}
.test-logo{
	padding: 4px;
	margin: 5px 20px;
}
#mobile-toolbar>.v-toolbar__content{
  justify-content:space-between
}
.timer-icon i{
  margin-bottom: -4px;
}
.table-bordered{
  border-collapse: collapse;
}
.table-bordered thead{
  padding: 5px 0
}
.table-bordered  td{
  border:1px solid #eee;
  padding: 5px;
}
</style>