<template>
<div>
  <div>
    <page-title>{{$route.meta.title}}</page-title>
    <v-dialog v-model="loading" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
        Loading data please wait...
        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-btn v-if="!hideRefresh" style="position:fixed; bottom: 10px; right: 0; z-index: 1;" dark fab right outline color="primary" @click="fetchTests()"><v-icon>sync</v-icon></v-btn>
  	<v-container
      v-if='!loading'
      grid-list-lg class="pa-0 mb-4 pb-4" >
      <v-layout row wrap justify-center>
          
          <v-flex xs12 md4 v-for="(test,ti) in tests" :key="ti">
            <v-card hover class="white--text">
              <!-- ${colors[ti<colors.length?ti:ti%colors.length]} -->
                  <v-card-title :style="`background:linear-gradient(45deg, rgb(0, 145, 234), green)`" style="padding: 10 0 10 0" >
                    <div class="headline white--text text-truncate" style="max-width: 80%;" :title="test.name">{{test.name}}</div>
                    <v-spacer></v-spacer>
                    <v-btn  flat @click="selectedTest=test;infoDialog=true" icon color="white"> <v-icon>info</v-icon> </v-btn>
                  </v-card-title>
                  
                  <v-card-text :class="{'pa-0': $vuetify.breakpoint.smAndDown}">
                    <v-list style="padding: 0">

                      <v-list-tile style="height: 40px">
                        <v-list-tile-content>Max Marks</v-list-tile-content>
                        <v-list-tile-content class="align-end">{{test.format.max_marks}}</v-list-tile-content>
                      </v-list-tile>

                      <v-list-tile style="height: 40px">
                        <v-list-tile-content>Questions</v-list-tile-content>
                        <v-list-tile-content class="align-end">{{test.format.noq || 0}}</v-list-tile-content>
                      </v-list-tile>

                      <v-list-tile style="height: 40px">
                        <v-list-tile-content>Test Date</v-list-tile-content>
                        <v-list-tile-content class="align-end">{{test.activation_date | activationTime}}</v-list-tile-content>
                      </v-list-tile>

                      <v-list-tile style="height: 40px">
                        <v-list-tile-content>Duration</v-list-tile-content>
                        <v-list-tile-content class="align-end">{{test.format.duration}} min</v-list-tile-content>
                      </v-list-tile>
                     
                      <v-list-tile style="height: 40px">
                        <v-list-tile-content>Multiple Attempt</v-list-tile-content>
                        <v-list-tile-content class="align-end">{{test.tags && test.tags.indexOf('b2c') > -1 ? true: false}}</v-list-tile-content>
                      </v-list-tile>

                      </v-list>
                  </v-card-text>
                  
              <v-divider light></v-divider> 
              <v-card-actions class="justify-center" >
                <template v-if="user && test.PDF_UPLOAD">
                  <v-btn :disabled="!test.attempted || test.resumeable"  @click="selectedTest=test; pdfDialog=true" color="indigo" outline round >PDF UPLOAD </v-btn>
                <!-- POST_PDF_UPLOAD -->
                <v-spacer></v-spacer>
                </template>
                <template v-if="user && user.config && !user.config.DISABLE_CHAT">
                  <v-btn flat color="primary" round @click="showChatPanel=ti; toggleChatPanel()" >CHAT {{chatCount[ti]>0?`(${chatCount[ti]})`:''}}</v-btn>
                <v-spacer></v-spacer>
                </template>
               
                <v-btn 
                  round 
                  :disabled="test | disableStart" 
                  :to="proceedLink(test)"
                  color="success">
                    {{test | timeAgo}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
          <v-flex xs12 v-if="showChatPanel>-1">
            <chat
              :test-mockid="tests[showChatPanel]._id" 
              :test-name="tests[showChatPanel].name"
              :sub-title="tests[showChatPanel].name"
              @close="showChatPanel=-1"
              @fetched="(cnt) => chatCount[showChatPanel] = cnt"
              ></chat>
          </v-flex>
          <v-flex xs12 class="justify-center" v-if="!tests.length">
            <!--<div class="text-xs-center">
              <v-icon :size="200" color="info" class="animate__animated animate__zoomIn">quiz</v-icon>
              <h3 class="headline mt-4 info--text animate__animated animate__slideInUp"> No assesment scheduled! </h3>
            </div>-->
            <page-no-data icon="quiz">No assesment scheduled!</page-no-data>
          </v-flex>
          
        </v-layout>
      </v-container>
     
  </div>

<post-pdf-upload
  v-if="Boolean(selectedTest)"
  :value="pdfDialog"
  :show="pdfDialog"
  :config="selectedTest.config"
  :testId="selectedTest.test_id"
  :testImages="selectedTest.iu || []"
  :pdfFiles="selectedTest.pu || []"
  :videoFiles="selectedTest.vu || []"
  :audioFiles="selectedTest.au || []"
  :excelFiles="selectedTest.eu || []"
  :pdfUpload="true"
  
  @close="pdfDialog=false; selectedTest = null"
  @fileUploaded="fileUploaded"
></post-pdf-upload>

<v-dialog
  v-model="infoDialog"
  width="500"
  :fullscreen="$vuetify.breakpoint.smAndDown">
  <v-card v-if="selectedTest">
    <v-card-title class="headline " style="background: rgb(0, 145, 234); color: white">
      <span class="headline">{{selectedTest.name}} </span>
      <v-spacer></v-spacer>
      <v-btn small fab icon @click="infoDialog=false"><v-icon>close</v-icon></v-btn>
    </v-card-title>

    <v-card-text>
      <h4>Description</h4>
      <p v-html="selectedTest.description"></p>
    </v-card-text>

  </v-card>
</v-dialog>

</div>
</template>
<script>

const {instance} = require('./../_axios.js');
import { mapActions, mapGetters } from 'vuex'
import PostPDFUpload from './Test/PostFileUpload'
import { DateTime, Duration } from "luxon";
import Chat from './../components/ChatOverlay.vue'

const chatCount = {}
for (let index = 0; index < 10; index++) {
  chatCount[index] = 0
}

export default{
	data() {
	  return {
      tests: [],
      loading: false,
      infoDialog: false,
      selectedTest: null,
      showChatPanel: -1,
      chatCount,
      chapterDialog: {
        model: false,
        chapters: []
      },
      selected: 0,
      mobNav: ['Select', 'Selected'],
      colors: ['#0091EA','#8bc34a','#ef5350','#ef6c00','#00bcd4','#455a64'],

      year: null,
      exam: null,
      testType: '',
      pdfDialog:false,
      hideRefresh: true
    }
	},
	mounted(){
    this.fetchTests()
  },
	methods:{
    ...mapActions(['errorSnack', 'toggleChatPanel']),
	  fetchTests(){
      this.hideRefresh = true
      this.loading = true
      const tags = this.$route.query.tags
	  	instance.get(`/test/list?q=${Date.now()}${tags?`&tags=${tags}`:''}`)
	  	.then((resp) => {
        this.tests = resp.data.sort((a,b) => b.order-a.order)
        setTimeout(() => {
          this.hideRefresh = false
        }, 10000)
	  	})
	  	.catch((e)=>{ this.errorSnack({e,b:'Error fetching tests!'}) })
      .then(() => { this.loading = false, 2000 }  )
    },
    proceedLink(test){
      if(!test) return '#'
      if(test.config.SKIP_PREPARE) return `/test/${test._id}/instruction?${test.resumeable?'resume=true':''}`
      //if(test.config.SKIP_PREPARE || this.user.config.SKIP_PREPARE) return `/test/${test._id}/instruction?${test.resumeable?'resume=true':''}`
      return `/test/${test._id}/ready-check?${test.resumeable?'resume=true':''}`
    },
    fileUploaded({data, type}){
      this.tests = this.tests.map(v => {
        if(v._id!==this.selectedTest._id) return v
        return { ...v, [type]: data }
      })
    }
	},
  watch:{
    '$route.path'(){
      this.tests = []
      this.fetchTests()
    }
  },
  filters: {
    timeAgo(test){
      if(test.resumeable) return 'Resume'
      
      if(test.time_to_start<0) {
       let temp = Duration.fromMillis(-1*test.time_to_start).shiftTo( 'days', 'hours', 'minutes', 'seconds' ).toObject()
       let out = ''
        Object.keys(temp).every((key) => {
          if(temp[key]>0) {
            out += temp[key]+' '+ key +' ago'
            return false
          }
          else return true
          // console.log(temp[key])
        })
       return `Test ended ${out}`
      }
      if(test.attempted) {
        if(test.multiple_attempt) return 'Proceed'
        return 'Attempted'
      }
      return 'Proceed'
    },
    activationTime(activation_time){
      if(!activation_time) return "-"
      try{
        return DateTime.fromISO(activation_time).toFormat('HH:mm a, dd LLL, yyyy') 
      } catch(e){
        return "-"
      }
    },
    disableStart(test) {
      if(test.attempted) {
        if(test.multiple_attempt) return false
        return !test.resumeable
      }
      if(test.time_to_start<0) return true
      return false
    }
  },
  computed:{
    ...mapGetters(['user']),
    
  },
  components: {
    'post-pdf-upload': PostPDFUpload,
    'chat': Chat,
  }
}
</script>
<style>

</style>