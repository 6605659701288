<template>
<v-container grid-list-md  class="pa-0">
<page-title>Test Results</page-title>
<v-layout row wrap>
  <v-flex xs12>
  	<!--<v-toolbar flat color="primary" dark style="border-radius: 5px 5px 0 0 ">
      <v-toolbar-title>Test Results</v-toolbar-title>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
      <v-spacer></v-spacer>
	</v-toolbar>-->
	<v-data-table
      :headers="headers"
      :items="results"
      :pagination.sync="pagination"
      select-all
      item-key="name"
      class="elevation-1"
      :loading="loading">

	       <template slot="no-data">
		      <v-alert :value="true" color="warning" icon="warning" outline>
		        No test result found!
		      </v-alert>
		    </template>

	      <template slot="headers" slot-scope="props">
	        <tr>
	          <th
	            v-for="header in props.headers"
	            :key="header.text"
	            :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']" 
	             @click="changeSort(header.value)">
	            <v-icon small>arrow_upward</v-icon>
	            {{ header.text }}
	          </th>
	        </tr>
	      </template>
	      <template slot="items" slot-scope="props">
	        <tr class="pa-4" :style="{background:props.index%2!=0?'#eee':'#fff'}">
	          <td class="nowrap" style="padding-top: 10px;padding-bottom: 10px" >
	          	<h4 class="subheading" >{{ props.item.name }}</h4>
	          	<!-- {{ props.item.chapters?props.item.chapters[0].name:''}} -->
	          	<p v-if="props.item.name=='Chapter Test'" style="font-size:12px">({{props.item.chapters.map(v => v.name).join(', ') | maxChar}})</p>
				 
				<i>{{props.item.test_created_at.split('T')[0].split('-').reverse().join('-')}}</i>
	          </td>
	          <td class="text-xs-center" v-if="!$vuetify.breakpoint.smAndDown"><h4 class="subheading" >{{ props.item.result.correct }} / {{ props.item.result.wrong }} / {{ Math.max(props.item.result.total - props.item.result.attempted, 0) }}</h4></td>
	          <!-- <td class="text-xs-center">{{ props.item.result.attempted }} / {{ props.item.result.skipped }}</td> -->
	          <td class="text-xs-center"><h4 class="subheading" >{{ props.item.result.scored }} / {{ props.item.result.max_marks }}</h4></td>
	          <td class="text-xs-center"><v-progress-circular :rotate="270" size="40" :width="5" :value="props.item.result.percentage" color="blue" :indeterminate="loading"> {{props.item.result.percentage || 0}} </v-progress-circular>
				</td>
	          <td class="text-xs-center">
          		<!-- <v-btn flat :to="{path:`/test/${props.item._id}/result`}" small fab title="Result"> <v-icon>note</v-icon></v-btn>
	          	<v-btn flat :to="{path:`/test/${props.item._id}/review`}" small fab title="Review"> <v-icon>track_changes</v-icon></v-btn> -->
	          	<!-- <router-link :to="{path:`/test/${props.item._id}/result`}" title="Result">Result</router-link> -->
				  <v-btn :to="{path:`/test/${props.item._id}/result`}" title="Result" round outline >Result</v-btn>
				  <v-btn v-if="!user.config.HIDE_TEST_REVIEW" :to="{path:`/test/${props.item._id}/review`}" title="Review" round outline deep-purple >Review</v-btn>
	          	<!-- <span v-if="$vuetify.breakpoint.mdAndUp">&nbsp;&nbsp;</span> -->
	          	<!-- <span v-else><br></span> -->
	          	<!-- <router-link :to="{path:`/test/${props.item._id}/review`}" title="Review">Review</router-link> -->
	          </td>
	        </tr>
	      </template>
	    </v-data-table>
  </v-flex>
</v-layout>
</v-container>
	
	
</template>
<script>
const {instance} = require('./../_axios.js');
import { mapActions, mapGetters } from 'vuex'
export default{
	data(){
      return {
      	loading: false,
      	results: [],
      	page: this.$route.query.page || 1,
      	pagination: {
	      sortBy: 'name',
	      rowsPerPage: 10
	    },
	    headers: [
	      {
	        text: 'Name',
	        align: 'left',
	        class: 'text-xs-left',
	        value: 'name'
	      },
	      { text: 'Correct / Wrong / Skipped',    	value: 'result.correct' },
	      // { text: 'Attempted / Skipped',  	value: 'result.attempted' },
	      { text: 'Scored / Max Marks',     value: 'result.scored' },
	      { text: 'Percentage', 			value: 'result.percentage' },
	      { text: 'Action', 			 }
	    ]
      }
	},
	mounted(){
	  this.fetchResults()
	  if(this.$vuetify.breakpoint.smAndDown){
	  	this.headers = this.headers.filter(v => v.value!='result.correct')
	  }
	},
	methods:{
      ...mapActions(['errorSnack']),
      fetchResults(){
      	this.loading = true
      	instance.get('/test/myresults', {params:{page: this.page}})
      	.then(resp => {
			console.log(resp.data)
      	   this.results = resp.data
      	})
      	.catch((e)=>{
			console.log(e)
      	   this.errorSnack({e,b:'Error fetching results!'}) 
      	})
      	.finally(() => {
          this.loading = false
      	})
      },
      changeSort (column) {
      	console.log(column)
        if (this.pagination.sortBy === column) {
          this.pagination.descending = !this.pagination.descending
        } else {
          this.pagination.sortBy = column
          this.pagination.descending = false
        }
      },
	},
	filters: {
		maxChar(v) {
		  if(v.length>30) return v.substring(0, 30)+' ...'
		  return v
		}
	},
	computed: {
		...mapGetters(['user'])
	}
}
</script>
<style>
tr>th:first-of-type {
	text-align: left
}
</style>